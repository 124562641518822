<style>
.input-readonly > div > .vs-inputx {
  background-color: #e6e6e6;
}
</style>
<template>
  <div class="vx-col w-5/6 ml-auto mr-auto">
    <span>
      <h4 class="mb-5">Credit Note Supplier</h4>
    </span>
    <div class="vx-row mb-2">
      <div class="vx-col w-1/2">
        <vs-input
          class="w-full input-readonly"
          label="Supplier Name"
          name="Vendor"
          :value="this.cnSupplier.supplier_name"
          readonly
        />
      </div>
      <div class="vx-col w-1/2">
        <vs-input
          class="w-full input-readonly"
          label="Supplier Code"
          name="Supplier Code"
          :value="this.cnSupplier.supplier_code"
          readonly
        />
      </div>
      <div class="vx-col w-full">
        <vs-input
          class="w-full input-readonly"
          label="Code"
          name="Invoice Number"
          :value="this.cnSupplier.code"
          readonly
        />
      </div>
      <div class="vx-col w-full">
        <vs-input
          class="w-full input-readonly"
          label="Total Debit"
          name="Invoice Number"
          :value="priceFormat(this.cnSupplier.total_debit)"
          readonly
        />
      </div>
      <div class="vx-col w-full">
        <vs-input
          class="w-full input-readonly"
          label="Total Credit"
          name="Currency Code"
          :value="priceFormat(this.cnSupplier.total_debit)"
          readonly
        />
      </div>
      <div class="vx-col w-full">
        <vs-input
          class="w-full input-readonly"
          label="Reference"
          name="Currency Code"
          :value="this.cnSupplier.reference"
          readonly
        />
      </div>
      <!-- <div class="vx-col w-full">
        <vs-input
          class="w-full input-readonly"
          label="Due Date"
          name="Due Date"
          :value="dateFormat(purchase.DueDate)"
          readonly
        />
      </div>
      <div class="vx-col w-full">
        <vs-input
          class="w-full input-readonly"
          label="Payment Term"
          name="Payment Term"
          :value="purchase.PaymentTermName"
          readonly
        />
      </div>
      <div class="vx-col w-full">
        <vs-input
          class="w-full input-readonly"
          label="Bank Name"
          name="Bank Name"
          :value="
            purchase.BankName +
            ' - ' +
            purchase.AccountNumber +
            ' ' +
            purchase.AccountName
          "
          readonly
        />
      </div> -->
    </div>
    <div class="vx-row mb-2 ml-1">
      <h5>Line Item</h5>
    </div>
    <vs-table stripe border description :sst="true" :data="table.line">
      <template slot="thead">
        <td>Customer Code</td>
        <td>Customer Name</td>
        <td>Coa</td>
        <td>Cost Center</td>
        <td>Debit</td>
        <td>Credit</td>
        <td>Description</td>
      </template>

      <template slot-scope="{ data }">
        <vs-tr
          :data="tr"
          :key="indextr"
          v-for="(tr, indextr) in data"
          :class="data[indextr].class"
        >
          <vs-td :data="data[indextr].code">
            <div class="vx-row ml-0">
              <span class="w-5/5">{{ tr.customer_code }}</span>
            </div>
          </vs-td>
          <vs-td> {{ tr.customer_name }}</vs-td>
          <vs-td>
            <div class="vx-row ml-0">
              <span class="w-5/5">{{ tr.chart_of_account_name }}</span>
            </div>
          </vs-td>
          <vs-td>
            <div class="vx-row ml-0">
              <span class="w-5/5">{{ tr.cost_center_name }}</span>
            </div>
          </vs-td>
          <vs-td>
            <div class="vx-row ml-0">
              <span class="w-5/5">{{ priceFormat(tr.value_debit) }}</span>
            </div>
          </vs-td>
          <vs-td>
            <div class="vx-row ml-0">
              <span class="w-5/5">{{ priceFormat(tr.value_credit) }}</span>
            </div>
          </vs-td>
          <vs-td>
            <div class="vx-row ml-0">
              <span class="w-5/5">{{ tr.description }}</span>
            </div>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <!-- 
    <div class="vx-row mb-2 mt-4 ml-1">
      <h5>Charges</h5>
    </div>
    <vs-table stripe border description :sst="true" :data="table.data_charges">
      <template slot="thead">
        <vs-th>Charge Name</vs-th>
        <vs-th>Value</vs-th>
        <vs-th>Tax</vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td>
            <div class="vx-row ml-0">
              <span class="w-5/5">{{
                data[indextr].charge_name.replace(/-/g, " ")
              }}</span>
            </div>
          </vs-td>
          <vs-td>
            <div class="vx-row ml-0">
              <span class="w-5/5">{{ data[indextr].value }}</span>
            </div>
          </vs-td>
          <vs-td>
            <div class="vx-row ml-0">
              <span class="w-5/5">{{ data[indextr].tax }}</span>
            </div>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table> -->
    <!-- <div class="flex flex-row gap-3 justify-start w-full mt-3">
      <vs-checkbox :disabled="true" v-model="vat_expired"
        >Vat Expired</vs-checkbox
      >
      <vs-checkbox :disabled="true" v-model="is_skb">SKB</vs-checkbox>
    </div>
    <div class="vx-row w-5/5 mb-3 mt-6">
      <vs-input
        class="w-1/6 input-readonly"
        label="Item Value"
        name="Item Value"
        v-model="table.item_value"
        readonly
      />

      <vs-input
        class="w-1/6 input-readonly pl-1 pr-1"
        label="Charges Value"
        name="Charges Value"
        v-model="table.charges_value"
        readonly
      />

      <vs-input
        class="w-1/6 input-readonly pr-1"
        label="Tax Value"
        name="Tax Value"
        v-model="table.tax_value"
        readonly
      />

      <vs-input
        class="w-1/6 input-readonly pr-1"
        label="Discount Value"
        name="Discount Value"
        v-model="table.discount_value"
        readonly
      />

      <vs-input
        class="w-1/6 input-readonly pr-1"
        label="Total Withholding Tax"
        name="Total Withholding Tax"
        v-model="table.total_whtax"
        readonly
      />

      <vs-input
        class="w-1/6 input-readonly"
        label="Total Invoice Value"
        name="Total Invoice Value"
        v-model="table.total_inv_value"
        readonly
      />
    </div>
    <div class="horizontal-divider text-center"></div>
    <div class="vx-row mb-2">
      <div class="vx-col w-1/3">
        <vs-input
          class="w-full input-readonly"
          label="Total Paid"
          name="Total Paid"
          v-model="total_paid"
          readonly
        />
      </div>
      <div class="vx-col w-1/3">
        <vs-input
          class="w-full input-readonly"
          label="Total Propose Value"
          name="Total Propose Value"
          v-model="total_process"
          readonly
        />
      </div>
      <div class="vx-col w-1/3">
        <vs-input
          class="w-full input-readonly"
          label="Total Unpaid"
          name="Total Unpaid"
          v-model="total_unpaid"
          readonly
        />
      </div>
    </div> -->
    <!-- <div class="vx-row mb-2" style="z-index: 99999 !important">
      <div class="vx-col w-full">
        <label class="vs-input--label">Bank Name</label>
        <multiselect
          class="selectExample"
          v-model="operatingUnitBank"
          :options="optionOperatingUnitBank"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          :max-height="160"
          :limit="4"
          placeholder=" Type to search"
          track-by="ID"
          label="Name"
          :disabled="false"
        >
          <template slot="singleLabel" slot-scope="dt">
            <span class="option__desc">
              <span class="option__title">{{
               optionBankName.filter(v => {
                  return v.id == dt.BankID
                })[0].Name + ' ' + dt.option.AccountNumber + ' (' + dt.option.AccountName + ')'
              }}</span>
            </span>
          </template>

          <template slot="option" slot-scope="dt">
            <div class="option__desc">
              <span class="option__title">{{
                optionBankName.filter(v => {
                  return v.id == dt.BankID
                })[0].Name + ' ' + dt.option.AccountNumber + ' (' + dt.option.AccountName + ')'
              }}</span>
            </div>
          </template>
        </multiselect>
        <span class="text-danger text-sm" v-if="bank == null && status_submit"
          >This field is required</span
        >
      </div>
    </div> -->
    <div class="vx-row mb-2">
      <!-- <div class="vx-col w-1/2">
        <vs-input
          v-validate="'required'"
          class="w-full"
          label="Account Number"
          name="Account Number"
          v-model="account_number"
          placeholder="Account Number"
        />
        <span
          class="text-danger text-sm"
          v-if="account_number == null && status_submit"
          >This field is required</span
        >
      </div> -->
      <div class="vx-col w-full">
        <div class="vx-col w-full">
          <span>Porpose Date Payment</span>
        </div>
        <div class="vx-col w-full">
          <datepicker
            class="w-full"
            v-validate="'required'"
            :inline="false"
            name="Porpose Date"
            v-model="porpose_date"
            placeholder="Select Porpose Date"
          ></datepicker>
        </div>
      </div>
      <div class="vx-col w-full">
        <vs-input
          v-validate="'required'"
          class="w-full"
          label="Propose Value"
          name="Propose Value"
          v-model="total_value"
          @input="formatNumber()"
        />
        <span
          class="text-danger text-sm"
          v-if="total_value == 0 && status_submit"
          >This field is required</span
        >
      </div>
      <div v-if="adjustment_value" class="vx-col mt-3 w-full">
        <vs-alert color="primary" title="Adjustment" active="true">
          <p>Credit note supplier has been adjusted.</p>
          <b>Adjustement value : {{ priceFormat(adjustment_value) }}</b>
        </vs-alert>
      </div>
      <vs-divider style="width: 100%; margin-left: 2%"
        ><b>Selected DN by Excel</b></vs-divider
      >
      <div class="vx-row mb-3 mt-6 w-4/4" style="width: 100%; margin-left: 0%">
        <div class="vx-col sm:w-1/3 w-full">
          <span>File</span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <div class="vx-col sm:w-4/5 w-full">
            <input
              id="fileSelectedDN"
              name="fileSelected"
              class="w-full inputx"
              type="file"
              ref="fileSelected"
              multiple="multiple"
              accept=".xlsx"
            />
          </div>
        </div>
      </div>
      <div class="vx-row mb-3 mt-6 w-4/4" style="width: 100%; margin-left: 0%">
        <div class="vx-col sm:w-1/3 w-full">
          <span></span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <div class="vx-col sm:w-4/5 w-full">
            <vs-button class="mr-3 mb-2" @click="loadExcel">Selected</vs-button>
          </div>
        </div>
      </div>
      <vs-divider style="width: 100%; margin-left: 2%"
        ><b>Debit Note</b></vs-divider
      >
      <multiselect
        class="selectExample"
        v-model="debitNote"
        :options="optionDebitNote"
        :multiple="true"
        :max="maxSelectedDN"
        @remove="removeSelectedDN"
        @input="selectedDN"
        :allow-empty="true"
        :group-select="false"
        :max-height="160"
        :limit="5"
        placeholder=" Type to search"
        track-by="ID"
        label="Code"
        :disabled="false"
      >
        <template slot="singleLabel" slot-scope="dt">
          <span class="option__desc">
            <span class="option__title">{{ dt.option.Code }}</span>
          </span>
        </template>

        <template slot="option" slot-scope="dt">
          <div class="option__desc">
            <span class="option__title">{{
              dt.option.Code +
              " : " +
              parseFloat(
                parseFloat(dt.option.TotalDnValue).toFixed(2) -
                  parseFloat(dt.option.UsedDnValue)
              )
                .toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }}</span>
            <span class="option__small">
              <br />
              [{{
                dt.option.Type == "Return" || dt.option.Type == "Manual"
                  ? "Trade"
                  : dt.option.ClaimType
              }}
              , {{ dt.option.CreatedAt }}]
            </span>
          </div>
        </template>
      </multiselect>
      <div class="vx-col sm:w-3/3 w-full" v-if="debitNote.length > 0">
        <vs-list>
          <vs-list-item
            v-for="(item, i) in debitNote"
            :key="item.Code"
            :title="item.Code"
            :subtitle="
              parseFloat(
                parseFloat(item.TotalDnValue) - parseFloat(item.UsedDnValue)
              )
                .toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            "
          >
            <div class="vx-row w-full">
              <div class="vx-col w-3/3">
                <vs-input
                  v-validate="'required'"
                  class="w-full"
                  label="Apply Value"
                  name="Apply Value"
                  v-model="applyValue[i]"
                  @keyup="handleMaxValue(i)"
                />
              </div>
            </div>
          </vs-list-item>
        </vs-list>
        <div class="vx-col sm:w-3/3 w-full">
          <div class="vx-col sm:w-4/5 w-full"></div>
        </div>
        <div class="vx-row mb-2">
          <div class="vx-col w-full">
            <span>Posting Date</span>
          </div>
          <div class="vx-col w-full">
            <datepicker
              class="w-full"
              v-validate="'required'"
              :inline="false"
              name="PostingDate"
              v-model="posting_date"
              placeholder="Select Posting Date"
            ></datepicker>
          </div>
        </div>
        <div class="vx-row sm:w-3/3 w-full">
          <div class="vx-col sm:w-2/3">
            <vs-button
              v-if="canApplyDN"
              class="mr-3 mb-2"
              @click="handleApplyDebitNote"
              >Apply Debit Note</vs-button
            >
          </div>
          <div class="vx-col sm:w-1/3">
            Total Selected :
            {{
              applyValue
                .reduce(
                  (a, b) =>
                    Number(a.toString().replace(/,/g, "")) +
                    Number(b.toString().replace(/,/g, "")),
                  0
                )
                .toString()
                .replace(/,/g, "")
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }}
          </div>
        </div>
      </div>
      <!-- <vs-divider style="width: 100%; margin-left: 2%"><b>Notes</b></vs-divider>
      <template v-for="note in notes">
        <b v-bind:key="note"
          >{{ note }}
          <br />
        </b>
      </template>
      <br /> -->
      <!-- <vs-textarea v-model="new_notes" />
      <vs-divider style="width: 100%; margin-left: 2%"
        ><b>Comment</b></vs-divider
      >
      <vs-textarea v-model="comment" />

      <vs-divider style="width: 100%; margin-left: 2%"
        ><b>Description</b></vs-divider
      >
      <vs-textarea v-model="description" />
      <vs-divider style="width: 100%; margin-left: 2%"
        ><b>List Payment</b></vs-divider
      > -->

      <vs-divider style="width: 100%; margin-left: 2%"
        ><b>Description</b></vs-divider
      >
      <vs-textarea v-model="description" />
      <vs-table
        stripe
        border
        description
        class="w-full"
        :sst="true"
        :data="table.data_payment"
      >
        <template slot="thead">
          <vs-td style="text-align: center"><b>Payment Account</b></vs-td>
          <vs-td style="text-align: center"><b>Payment Voucher</b></vs-td>
          <vs-td style="text-align: right"><b>Paid</b></vs-td>
        </template>

        <template slot-scope="{ data }">
          <vs-tr
            :data="tr"
            :key="indextr"
            v-for="(tr, indextr) in data"
            :class="data[indextr].class"
          >
            <vs-td style="text-align: center" :data="data[indextr].BankName">
              {{
                data[indextr].BankID == 0
                  ? data[indextr].Type == 2
                    ? "DN"
                    : "-"
                  : data[indextr].BankName + " " + data[indextr].AccountNumber
              }}
            </vs-td>
            <vs-td style="text-align: center">
              {{ data[indextr].ReferenceCode }}
            </vs-td>
            <vs-td style="text-align: right">
              {{
                data[indextr].PaidInvoiceValue.toString().replace(
                  /\B(?=(\d{3})+(?!\d))/g,
                  ","
                )
              }}
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <vs-divider style="width: 100%; margin-left: 2%"
        ><b>Attachment</b></vs-divider
      >
      <div class="vx-row mb-3 mt-6 w-4/4" style="width: 100%; margin-left: 0%">
        <div class="vx-col sm:w-1/3 w-full">
          <span>File</span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <div class="vx-col sm:w-4/5 w-full">
            <input
              id="fileInput"
              name="file"
              class="w-full inputx"
              type="file"
              ref="file"
              multiple="multiple"
              accept=".jpg, .jpeg, .png, .pdf"
            />
          </div>
        </div>
      </div>
      <div class="vx-row mb-3 mt-6 w-4/4" style="width: 100%; margin-left: 0%">
        <div class="vx-col sm:w-1/3 w-full">
          <span></span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <div class="vx-col sm:w-4/5 w-full">
            <vs-button class="mr-3 mb-2" @click="handleAttachment"
              >Add Attachment</vs-button
            >
          </div>
        </div>
      </div>
      <vs-divider style="width: 100%; margin-left: 2%"
        ><b>List Attachment</b></vs-divider
      >
      <div class="vx-row mb-3 mt-6 w-4/4" style="width: 100%; margin-left: 0%">
        <table class="vs-table vs-table--tbody-table">
          <template v-for="(tr, i) in table.file">
            <tr
              class="tr-values vs-table--tr tr-table-state-null selected"
              v-bind:key="tr.name"
            >
              <td class="td vs-table--td">{{ tr.file_name }}</td>
              <td class="td vs-table--td">
                <vx-tooltip text="Show" v-if="tr.path != ''">
                  <vs-button
                    type="line"
                    icon-pack="feather"
                    @click.stop="downloadFileAwsS3(tr.path)"
                    icon="icon-eye"
                  />
                </vx-tooltip>
              </td>
              <td class="td vs-table--td">
                <template>
                  <vx-tooltip text="Delete">
                    <vs-button
                      type="line"
                      icon-pack="feather"
                      @click.stop="handleDeleteAttachment(i, tr.ID)"
                      icon="icon-trash"
                    />
                  </vx-tooltip>
                </template>
              </td>
            </tr>
          </template>
        </table>
      </div>
      <vs-divider style="width: 100%; margin-left: 2%"></vs-divider>

      <!-- <div class="vx-row mb-6 mt-6">
        <div class="vx-col w-full" style="margin-bottom: 150px">
          <vs-button class="mb-2" @click="handleSubmit"
            >Submit for Approval</vs-button
          >
        </div>
      </div> -->
    </div>
  </div>
</template>
<script>
import Datepicker from "vuejs-datepicker";
import moment from "moment";
import XLSX from "xlsx";

export default {
  components: {
    Datepicker,
  },
  props: {
    piId: {
      type: Number,
    },
    vendor: {
      type: String,
    },
    poCode: {
      type: String,
    },
    invCode: {
      type: String,
    },
    warehouseCode: {
      type: String,
    },
    warehouseName: {
      type: String,
    },
    createdDate: {
      type: String,
    },
    cnSupplier: {
      type: Object,
      default: () => ({}),
    },
  },
  created() {},
  data() {
    return this.initialState();
  },
  methods: {
    initialState() {
      return {
        porpose_date: null,
        debitNote: [],
        applyValue: [],
        applyID: [],
        optionDebitNote: [],
        optionPaymentTerm: [],
        optionBankName: [],
        optionOperatingUnitBank: [],
        operatingUnitBank: null,
        tr_number: null,
        tr_date: null,
        posting_date: new Date().toISOString().slice(0, 10),
        search: "",
        notes: "",
        comment: "",
        new_notes: "",
        offset: 0,
        limit: 10,
        status_submit: false,
        maxSelectedDN: 999,
        table: {
          inv_code: null,
          inv_date: null,
          due_date: null,
          payment_term: null,
          bank_name: null,
          account_name: null,
          account_number: null,
          total_inv_value: 0,
          item_value: 0,
          charges_value: 0,
          tax_value: 0,
          data: [],
          data_charges: [],
          line: [],
          file: [],
        },
        bank: null,
        total_paid: 0,
        total_unpaid: 0,
        total_process: 0,
        account_number: null,
        total_value: 0,
        canApplyDN: true,
        file: {},
        description: "",
        is_skb: false,
        vat_expired: false,
        adjustment_value: 0,
      };
    },

    async loadExcel() {
      console.log(this.$refs.fileSelected.files[0], "load");
      this.$vs.loading();
      var file = this.$refs.fileSelected.files[0];
      const data = await file.arrayBuffer();
      var workbook = XLSX.readFile(data, { dense: true });
      var worksheet = workbook.Sheets[workbook.SheetNames[0]];
      var json = XLSX.utils.sheet_to_json(worksheet, {
        header: 1,
      });
      console.log(json, "json");
      this.$vs.loading.close();
      document.querySelector("#fileSelectedDN").value = "";
      this.processSelectedDN(json);
      // }

      // reader.readAsArrayBuffer(file);
      // console.log(file)
    },
    handleMaxValue(index) {
      // return this.formatPrice(paid_now)
      // dataChecked[checked.length - 1].paid_now = formatPrice(
      //                 dataChecked[checked.length - 1].paid_now.toString() == ''? '0': (tr.unpaid_value < dataChecked[checked.length - 1].paid_now ? tr.unpaid_valuez.toString():dataChecked[checked.length - 1].paid_now.toString() )
      //               )
      if (
        Number(this.applyValue[index].toString().replace(/,/g, "")) >
        Number(
          this.debitNote[index].TotalDnValue - this.debitNote[index].UsedDnValue
        )
      ) {
        this.applyValue[index] = Number(
          this.debitNote[index].TotalDnValue - this.debitNote[index].UsedDnValue
        )
          .toString()
          .replace(/,/g, "")
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      } else {
        this.applyValue[index] = this.applyValue[index]
          .toString()
          .replace(/,/g, "")
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
      // this.$nextTick(() => {
      console.log(this.applyValue[index]);
      // return this.applyValue[index]
      // })
    },
    formatNumber() {
      if (
        Number(this.total_value.toString().replace(/,/g, "")) >
        Number(this.total_unpaid.toString().replace(/,/g, ""))
      ) {
        this.$vs.notify({
          color: "warning",
          title: "Information",
          text: "Check total unpaid.",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        this.total_value = this.total_unpaid
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      } else {
        this.total_value = Number(this.total_value.toString().replace(/,/g, ""))
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    handleSubmit() {
      this.status_submit = true;
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$vs.loading();
          this.doSubmit();
        }
      });
    },
    handleDeleteAttachment(index, id) {
      // this.table.file = this.table.file.filter((item, i) => i != index);
      this.deleteFile(id);
      // console.log(this.table.file);
    },
    handleAttachment() {
      console.log(this.$refs.file.files[0]);

      if (this.$refs.file.files.length > 0) {
        this.file = this.$refs.file.files;
        for (let i = 0; i < this.$refs.file.files.length; i++) {
          console.log(i, "ini i");
          this.uploadData(i);
        }
        document.querySelector("#fileInput").value = "";
      } else {
        this.$vs.notify({
          title: "Error",
          text: "Please select file to import.",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
      }
    },
    handleShowAttachment(selected) {
      if (selected.path != "") {
        window.open(selected.path);
      }
    },
    paramUpload(array) {
      var form = new FormData();
      form.append("id", this.cnSupplier.ID);
      form.append("file", this.file[array]);
      return form;
    },
    uploadData(array) {
      this.$vs.loading();
      this.$http
        .post(
          "/api/v1/credit-note-supplier/upload-file",
          this.paramUpload(array),
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((resp) => {
          this.table.file.push(resp.data.attachment);
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    deleteFile(id) {
      this.$vs.loading();
      var form = new FormData();
      form.append("id", id);
      this.$http
        .post("/api/v1/credit-note-supplier/delete-file", form, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            const index = this.table.file.findIndex(
              (v) => v.ID === resp.data.ID
            );
            this.table.file.splice(index, 1);
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    doSubmit() {
      let param = {
        pi_id: this.piId,
        cn_supplier_id: this.cnSupplier.ID ? this.cnSupplier.ID : null,
        // inv_code: this.invCode,
        // bank_id: this.bank.id ? this.bank.id : this.bank.ID,
        // bank_name: this.bank.name ? this.bank.name : this.bank.Name,
        // account_number: this.account_number,
        // bank_id: this.operatingUnitBank.BankID,
        // bank_name: this.optionBankName.filter(v => {
        //   return v.ID == this.operatingUnitBank.BankID
        // })[0].Name,
        porpose_date: moment(this.porpose_date).format("YYYY-MM-DD"),
        total_value: this.total_value,
        total_paid: this.total_paid,
        notes: this.new_notes,
        comment: this.comment,
        description: this.description,
        new_code:
          this.table.inv_code !== this.invCode ? this.table.inv_code : null,
      };
      this.$http
        .post("/api/v1/pi/propose-payment", param)
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.handleClose();
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    handleClose() {
      window.scrollTo(0, 0);
      this.$emit("close");
    },
    getOptionBankName() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/bank", {
          params: {
            order: "name",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.optionBankName = resp.data.records;
            console.log("INI RESPON DATA", resp.data);
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to get Bank Name option",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$vs.loading.close();
          this.getData();
        });
    },
    handleApplyDebitNote() {
      this.$vs.loading();
      let totalUnpaid = parseFloat(
        this.total_unpaid.toString().replace(/,/g, "")
      );
      const debitNote = [];
      let valueApplied = [];
      this.debitNote.map((v) => {
        const dnValue = v.TotalDnValue - v.UsedDnValue;
        let dnApplied = 0;
        if (totalUnpaid >= dnValue) {
          dnApplied = dnValue;
          totalUnpaid = totalUnpaid - dnValue;
        } else {
          dnApplied = totalUnpaid;
          totalUnpaid = 0;
        }

        debitNote.push(v.ID);
        valueApplied.push(dnApplied);
      });
      valueApplied = this.applyValue.map((v) => {
        return parseFloat(v.toString().replace(/,/g, ""));
      });
      let param = {
        invoice_id: this.piId,
        debit_note: debitNote,
        value_applied: valueApplied,
        posting_date: this.posting_date,
      };
      // console.log(this.debitNote,param)
      this.$http
        .post("/api/v1/pi/apply-debit-note", param)
        .then((resp) => {
          this.applyValue = [];
          this.$vs.loading.close();
          this.getData();
          if (resp.code == 200) {
            // this.handleClose();
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    getData() {
      this.$vs.loading();
      this.$http
        .get(
          "/api/v1/credit-note-supplier/line-for-proposal/" + this.cnSupplier.ID
        )
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            // if (resp.data.unpaid_value == 0) {
            //   this.$vs.notify({
            //     color: "success",
            //     title: "Success",
            //     text: this.invCode + " already paid",
            //     position: "top-right",
            //     iconPack: "feather",
            //     icon: "icon-x-circle",
            //   });
            //   this.handleClose();
            //   return;
            // }
            // this.table.inv_date =
            //   this.invCode != 0
            //     ? moment(String(new Date(resp.data.inv_date))).format(
            //         "DD/MM/YYYY"
            //       )
            //     : null;
            // this.table.due_date =
            //   this.invCode != 0
            //     ? moment(String(new Date(resp.data.due_date))).format(
            //         "DD/MM/YYYY"
            //       )
            //     : null;
            this.debitNote = [];
            this.table.file = resp.data.attachments;
            this.table.line = resp.data.lines;
            this.total_value = resp.data.total_value
              .toFixed(2)
              .toString()
              .replace(/,/g, "")
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.total_paid = resp.data.total_paid
              .toFixed(2)
              .toString()
              .replace(/,/g, "")
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.table.data_payment = resp.data.payment;
            this.description = this.cnSupplier.Description;
            this.adjustment_value = resp.data.adjustment_total_value;
            // this.notes = resp.data.invoice_notes.toString().split(";");
            // this.table.payment_term_name = resp.data.payment_term_name;
            // this.table.data_payment = resp.data.detail_payment;
            // this.table.bank_name =
            //   resp.data.bank_name +
            //   " - " +
            //   resp.data.account_number +
            //   " " +
            //   resp.data.account_name;
            // this.tr_date =
            //   moment(String(resp.data.tr_date)).format("YYYY-MM-DD") ==
            //   "0001-01-01"
            //     ? ""
            //     : moment(String(new Date(resp.data.tr_date))).format(
            //         "DD/MM/YYYY"
            //       );
            // this.tr_number = resp.data.tr_number;
            // this.table.discount_value = resp.data.total_discount
            //   .toFixed(2)
            //   .toString()
            //   .replace(/,/g, "")
            //   .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            // this.total_paid = resp.data.paid_value
            //   .toFixed(2)
            //   .toString()
            //   .replace(/,/g, "")
            //   .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            // this.table.total_whtax = resp.data.data_line.reduce(
            //   (accumulator, currentObj) => {
            //     if (resp.data.purchase_invoice.IsSkb === 1) {
            //       return accumulator;
            //     } else {
            //       return accumulator + currentObj.wh_tax;
            //     }
            //   },
            //   0
            // );
            // this.vat_expired =
            //   resp.data.purchase_invoice.VatExpired === 0 ? false : true;
            // this.is_skb = resp.data.purchase_invoice.IsSkb === 1 ? true : false;
            // this.total_unpaid = (
            //   resp.data.unpaid_value - this.table.total_whtax
            // )
            //   .toFixed(2)
            //   .toString()
            //   .replace(/,/g, "")
            //   .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            // this.total_process = resp.data.proposal_value
            //   .toFixed(2)
            //   .toString()
            //   .replace(/,/g, "")
            //   .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            // this.total_value = (resp.data.unpaid_value - this.table.total_whtax)
            //   .toFixed(2)
            //   .toString()
            //   .replace(/,/g, "")
            //   .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            // this.table.total_whtax = this.table.total_whtax
            //   .toFixed(2)
            //   .toString()
            //   .replace(/,/g, "")
            //   .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            // this.table.total_inv_value = resp.data.total_invoice_value
            //   .toFixed(2)
            //   .toString()
            //   .replace(/,/g, "")
            //   .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            // this.table.item_value = resp.data.item_value
            //   .toFixed(2)
            //   .toString()
            //   .replace(/,/g, "")
            //   .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            // this.table.charges_value = resp.data.charge_value
            //   .toFixed(2)
            //   .toString()
            //   .replace(/,/g, "")
            //   .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            // this.table.tax_value = resp.data.tax_value
            //   .toFixed(2)
            //   .toString()
            //   .replace(/,/g, "")
            //   .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            // resp.data.debit_note.map((v, i) => {
            //   resp.data.debit_note[i].CreatedAt = moment(
            //     resp.data.debit_note[i].CreatedAt
            //   ).format("YYYY-MM-DD");
            // });
            this.optionDebitNote = resp.data.debit_notes;
            // this.optionOperatingUnitBank = resp.data.data_operating_unit_bank;
            // this.operatingUnitBank = this.optionOperatingUnitBank.filter(
            //   (v) => {
            //     return (
            //       v.BankID == resp.data.bank_id_proposal &&
            //       v.AccountNumber == resp.data.account_number_proposal
            //     );
            //   }
            // )[0];
            // this.table.po_code = resp.data.po_code;
            // this.table.vendor = resp.data.vendor;

            // let _this = this;
            // resp.data.data_line.forEach(function (element, index) {
            //   _this.table.data.push({
            //     net: element.net
            //       .toFixed(2)
            //       .toString()
            //       .replace(/,/g, "")
            //       .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            //     tax: element.tax
            //       .toFixed(2)
            //       .toString()
            //       .replace(/,/g, "")
            //       .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            //     quantity: element.quantity
            //       .toString()
            //       .replace(/,/g, "")
            //       .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            //     discount: element.discount
            //       .toFixed(2)
            //       .toString()
            //       .replace(/,/g, "")
            //       .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            //     item_name: element.item_name,
            //     item_unit: element.item_unit,
            //     id: element.id,
            //     qty_received: element.qty_received,
            //     subtotal: (element.net - element.discount)
            //       .toFixed(2)
            //       .toString()
            //       .replace(/,/g, "")
            //       .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            //     wh_tax: element.wh_tax
            //       .toFixed(2)
            //       .toString()
            //       .replace(/,/g, "")
            //       .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            //   });
            // });

            // resp.data.data_charge.forEach(function (element, index) {
            //   _this.table.data_charges.push({
            //     value: element.value
            //       .toString()
            //       .replace(/,/g, "")
            //       .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            //     tax: element.tax
            //       .toString()
            //       .replace(/,/g, "")
            //       .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            //     id: element.id,
            //     charge_name: element.charge_name,
            //     tax_rate: element.tax_rate,
            //   });
            // });
            // this.comment = resp.data.purchase_invoice.CommentInvoice;
            // resp.data.data_attachment.map((r, i) => {
            //   _this.table.file.push({
            //     id: r.id,
            //     name: r.name,
            //     path: r.path,
            //   });
            // });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    removeSelectedDN(...args) {
      for (var i = 0; i < this.debitNote.length; i++) {
        console.log(args[0].ID, this.applyID[i]);
        if (args[0].ID == this.applyID[i]) {
          this.applyID.splice(i, 1);
          this.applyValue.splice(i, 1);
          break;
        }
      }
      console.log(args, "args");

      this.maxSelectedDN = 999;
    },
    processSelectedDN(json) {
      this.$loa;
      console.log(json);
      json.map((v, r) => {
        if (r > 0 && v.length > 0) {
          const filter = this.optionDebitNote.filter((subv) => {
            return v[0] == subv.Code;
          });
          if (filter.length > 0) {
            const unUsed =
              parseFloat(filter[0].TotalDnValue) -
              parseFloat(filter[0].UsedDnValue);
            console.log(unUsed, v[2]);
            if (unUsed >= parseFloat(v[2])) {
              const checkSelected = this.debitNote.filter((subv) => {
                return v[0] == subv.Code;
              });
              if (checkSelected.length < 1) {
                this.debitNote.push(filter[0]);
                this.applyID.push(filter[0].ID);
                if (this.applyValue.length < this.debitNote.length) {
                  this.applyValue.push(parseFloat(v[2]));
                } else {
                  this.applyValue[this.applyValue.length - 1] = parseFloat(
                    v[2]
                  );
                }
              } else {
                this.$vs.notify({
                  color: "warning",
                  title: "Information",
                  text: v[0] + " already selected",
                  position: "top-right",
                  iconPack: "feather",
                  icon: "icon-x-circle",
                });
              }
            } else {
              this.$vs.notify({
                color: "warning",
                title: "Information",
                text: v[0] + " value greater than remaining debit note",
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
            }
          } else {
            this.$vs.notify({
              color: "warning",
              title: "Information",
              text: v[0] + " not found",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
          console.log(filter);
          console.log(v, r, "r");
        }
      });
      // this.optionDebitNote.map(item => {
      //   // console.log(item, 'item')
      // })
    },
    selectedDN(selectedOption, id) {
      console.log(selectedOption, id);
      console.log(this.total_unpaid);
      this.applyID.push(this.debitNote[this.debitNote.length - 1].ID);
      if (this.applyValue.length < this.debitNote.length) {
        this.applyValue.push(
          parseFloat(this.debitNote[this.debitNote.length - 1].TotalDnValue) -
            parseFloat(this.debitNote[this.debitNote.length - 1].UsedDnValue)
        );
      }
      console.log(this.debitNote);
      const totalSelectedDebitNote = this.applyValue.reduce(
        (prev, curr) => prev + curr,
        0
      );
      this.canApplyDN = true;
      console.log(this.debitNote.length);
      console.log(
        parseFloat(this.total_unpaid.toString().replace(/,/g, "")) <
          totalSelectedDebitNote,
        parseFloat(this.total_unpaid.toString().replace(/,/g, ""))
      );
      if (
        parseFloat(this.total_unpaid.toString().replace(/,/g, "")) <
        totalSelectedDebitNote
      ) {
        this.maxSelectedDN = this.debitNote.length;
        this.canApplyDN = false;
      }
    },
  },
  mounted() {
    this.porpose_date = new Date();
    if (this.cnSupplier.ID) {
      // this.getData();
      this.getOptionBankName();
      this.table.inv_code = this.invCode != 0 ? this.invCode : null;
    }
  },
  computed: {},
  watch: {
    piId() {
      Object.assign(this.$data, this.initialState());
      // this.getData();
      this.getOptionBankName();
      this.table.inv_code = this.invCode != 0 ? this.invCode : null;
    },
    applyValue: {
      // This will let Vue know to look inside the array
      deep: true,

      // We have to move our method to a handler field
      handler() {
        this.debitNote.map((v, i) => {
          this.handleMaxValue(i);
        });
        const totalSelectedDebitNote = this.applyValue
          .map((v) => {
            return parseFloat(v.toString().replace(/,/g, ""));
          })
          .reduce((prev, curr) => prev + curr, 0);
        this.canApplyDN = true;
        this.maxSelectedDN = 9999;
        console.log(totalSelectedDebitNote);
        if (
          parseFloat(this.total_unpaid.toString().replace(/,/g, "")) <
          totalSelectedDebitNote
        ) {
          this.maxSelectedDN = this.debitNote.length;
          this.canApplyDN = false;
        }
      },
    },
  },
};
</script>
<style scoped>
.pagination {
  display: flex;
  margin: 0.25rem 0.25rem 0;
}

.pagination button {
  flex-grow: 1;
}

.pagination button:hover {
  cursor: pointer;
}

.horizontal-divider {
  border-top: 1px solid #c1bbff;
  padding: 5px;
  margin-top: 15px;
}
</style>
